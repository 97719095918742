import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OrderHistoryHttpService } from './order-history-http.service';
import { ChangeType, OrderHistoryItem } from './order-history.model';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OrderHistoryService } from './order-history.service';
import { HistoryTranslationService } from './history-translation.service';
import { Order } from '../../../order.model';

@Component({
  selector: 'hpm-history',
  templateUrl: './history.component.html',
  styleUrl: './history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<void> = new Subject<void>();
  timelineList: OrderHistoryItem[] = [];
  order: Order;
  selectedTabIndex = 0;
  protected readonly changeType = ChangeType;

  constructor(
    @Inject('Order') order: Order,
    private orderHistoryHttpService: OrderHistoryHttpService,
    private orderHistoryService: OrderHistoryService,
    private translateHistory: HistoryTranslationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.order = order;
  }

  ngOnInit(): void {
    this.selectionChanged(this.selectedTabIndex);
  }

  private loadFullHistoryForOrder(): void {
    if (this.order.id) {
      this.orderHistoryHttpService
        .getFullHistoryForOrder(this.order.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((historyEntries) => {
          this.timelineList = historyEntries;
          this.setIsLastStatusForHistory(this.timelineList);
          this.cdr.detectChanges();
        });
    }
  }

  private loadStatusHistoryForOrder(): void {
    if (this.order.id) {
      this.orderHistoryHttpService
        .getStatusHistoryForOrder(this.order.id)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((historyEntries) => {
          this.timelineList = historyEntries;
          this.setIsLastStatusForHistory(this.timelineList);
          this.cdr.detectChanges();
        });
    }
  }

  private setIsLastStatusForHistory(timeLineEntries: OrderHistoryItem[]): void {
    let i = 0;
    // set current status to all items, until we have a status change
    while (
      i < timeLineEntries.length &&
      timeLineEntries[i].field !== ChangeType.STATUS
    ) {
      timeLineEntries[i].isLastState = true;
      i++;
    }
    if (i < timeLineEntries.length) {
      // set item, which set status as well
      timeLineEntries[i].isLastState = true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  closeDialog(): void {
    this.orderHistoryService.closeDialog();
  }

  getChangeText(history: OrderHistoryItem): Observable<string> {
    return this.translateHistory.getChangeText(history);
  }

  selectionChanged(selectedIndex: number): void {
    this.selectedTabIndex = selectedIndex;
    if (selectedIndex === 1) {
      this.loadFullHistoryForOrder();
    } else {
      this.loadStatusHistoryForOrder();
    }
  }
}
