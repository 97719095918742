import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailCleaningDevice } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-cleaning-device',
  templateUrl: './cleaning-device.component.html',
  styleUrl: './cleaning-device.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleaningDeviceComponent {
  @Input() detail!: DetailCleaningDevice;
}
