<div class="contact-input">
  <mat-form-field class="contact-name">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT.NAME' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.name" />
  </mat-form-field>
  <mat-form-field class="contact-company">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT.COMPANY' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.company" />
  </mat-form-field>

</div>
