<div class="cleaning-program">
  <mat-form-field class="program">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.PROGRAM' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.program"/>
  </mat-form-field>

  <mat-form-field class="temperature">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.TEMPERATURE' | translate }}</mat-label>
    <input matInput type="number" hpmAreaChangeListener [(ngModel)]="detail.temperature"/>
  </mat-form-field>
</div>
