<form [formGroup]="protocolForm">
  <div class="col-12">
    <h5>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.HEADLINE_UPLOADER" | translate }}</h5>
    <mat-form-field>
      <mat-label>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.UPLOAD.DATE_OF_INSPECTION" | translate }}</mat-label>
      <input matInput formControlName="inspectionDate" [matDatepicker]="picker">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <cx-file-uploader
      [multiple]="true"
      [buttonText]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.UPLOAD.BUTTON' | translate"
      [description]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.UPLOAD.DESCRIPTION' | translate"
      [errorMessage]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.UPLOAD.ERROR_MESSAGE' | translate"
      [heading]=" 'ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.HEADLINE_UPLOADER' | translate"
      [endpoint]="getUploaderEndpoint()"
      [acceptedFileTypes]="uploaderAcceptedFiletypes"
    ></cx-file-uploader>

    <h5>{{ "ORDER_DETAIL.HYGIENE_INSPECTION.PROTOCOL.UPLOAD.HEADLINE_FILELIST" | translate }}</h5>

    <ng-container *ngFor="let document of documentList">
      <li class="cx-list-item d-flex border-radius">
        <mat-icon>receipt</mat-icon>
        <div class="document">
          <div>
            <a hpmSecureDownload [url]="downloadDocument(document.id)"
               [fileName]="document.filename">{{ document.filename }}</a>
          </div>
          <div class="fileSize">{{ bytesToHuman(document.size) }}</div>
        </div>
        <div class="spacer"></div>
        <button mat-icon-button (click)="deleteDocument(document.id)">
          <mat-icon>close</mat-icon>
        </button>
      </li>
      <div class="ruler"></div>
    </ng-container>
  </div>
</form>
