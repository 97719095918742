import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Order } from '../../../order.model';

@Component({
  selector: 'hpm-hygiene-inspection',
  templateUrl: './hygiene-inspection.component.html',
  styleUrl: './hygiene-inspection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HygieneInspectionComponent {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();

  currentStep = 1;

  setCurrentStep(step: number): void {
    this.currentStep = step;
  }

  nextStep(): void {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  prevStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }
}
