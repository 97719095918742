import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  VisibilityControl,
  VisibilityController,
} from '../../../service/visibility.controller';
import { ProductProposal } from '../../../../../../../../shared/model/product-proposal';
import { AreaChangeDetection } from '../../area-change-detection.service';
import { ProductProposalService } from '../../../../../../../../shared/service/product-proposal.service';
import { DetailCleaningControlSolution } from '../../../model/detail-questions.model';

export enum ControlSolutionTime {
  TUBERKULOZID = 'TUBERKULOZID',
}

@Component({
  selector: 'hpm-cleaning-control-solution',
  templateUrl: './cleaning-control-solution.component.html',
  styleUrl: './cleaning-control-solution.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class CleaningControlSolutionComponent implements OnInit {
  @Input() detail!: DetailCleaningControlSolution;
  @Input() visibilityController: VisibilityController =
    new VisibilityController();
  @Input() area!: string;
  @Input() questionId!: string;
  cleaningControlSolutionRowVisibilityControl: VisibilityControl =
    new VisibilityControl();
  protected readonly controlSolutionEfficiency = ControlSolutionTime;
  selectedProductProposal: Map<number, ProductProposal | undefined> = new Map<
    number,
    ProductProposal | undefined
  >();

  constructor(
    private areaChangeDetection: AreaChangeDetection,
    private productProposalService: ProductProposalService,
  ) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty();
    this.cleaningControlSolutionRowVisibilityControl =
      this.visibilityController.buildVisibilityControlForCondition(
        this.detail.conditionForCleaningSolution,
      );
  }

  showCleaningControlSolutionRow(): boolean {
    return this.cleaningControlSolutionRowVisibilityControl.isShown();
  }

  private ensureInputNotEmpty(): void {
    if (this.detail.products?.length === 0) {
      this.detail.products = [
        {
          product: '',
          applicationTime: '',
          volumeUnit: 'L',
          concentrateUnit: '%',
        },
      ];
    }
  }

  addLine(index: number): void {
    this.detail.products.splice(index + 1, 0, {
      product: '',
      applicationTime: '',
      volumeUnit: 'L',
      concentrateUnit: '%',
    });
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    if (this.detail.products.length <= index) {
      return;
    }
    this.detail.products.splice(index, 1);
    this.ensureInputNotEmpty();
    this.areaChangeDetection.onChange();
  }

  onSelectProductProposal(
    index: number,
    productProposal: ProductProposal | undefined,
  ): void {
    if (productProposal) {
      this.selectedProductProposal.set(index, productProposal);
      if (this.detail.products.length <= index) {
        return;
      }
      if (!this.detail.products[index].time) {
        this.detail.products[index].time =
          productProposal.efficiency.toUpperCase();

      }
    }
  }

  changedProductTime(index: number): void {
    const allProductProposals =
      this.productProposalService.getProductProposalsByAreaAndQustionId(
        this.area,
        this.questionId,
      );
    const productProposals = allProductProposals?.filter((productProposal) => {
      return (
        productProposal.name === this.detail.products[index].product &&
        productProposal.efficiency.toUpperCase() ===
          this.detail.products[index].time
      );
    });
    if (productProposals?.length) {
      this.selectedProductProposal.set(index, productProposals[0]);
    } else {
      this.selectedProductProposal.set(index, undefined);
    }
  }
}
