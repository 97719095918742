import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OrderRoutingModule } from './order-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderDetailComponent } from './form/order-detail.component';
import { GeneralFormComponent } from './form/general/general-form.component';
import { LogoUploadComponent } from './form/general/logo-upload/logo-upload.component';
import { MedicalPractiseComponent } from './form/general/medical-practise/medical-practise.component';
import { StatusChangeComponent } from './form/general/status-change/status-change.component';
import { HygieneInspectionComponent } from './form/hygiene-inspection/hygiene-inspection.component';
import { StepperModule } from '@bbraun/cortex/stepper';
import { UtilModule } from '../../shared/util/util.module';
import { UIModule } from '../../ui.module';
import { LoginModule } from '../../login/login.module';
import { HistoryComponent } from './form/history/history.component';
import { PreliminaryChecklistComponent } from './form/hygiene-inspection/preliminary-checklist/preliminary-checklist.component';
import { DocumentsComponent } from './form/documents/documents.component';
import { UploadDocumentsComponent } from './form/documents/upload-documents/upload-documents.component';
import { DocumentTableComponent } from './form/documents/document-table/document-table.component';
import { ProtocolComponent } from './form/hygiene-inspection/protocol/protocol.component';
import { InventoryComponent } from './form/hygiene-inspection/inventory/inventory.component';
import { AreaComponent } from './form/hygiene-inspection/questionnaire/area/area.component';
import { QuestionComponent } from './form/hygiene-inspection/questionnaire/area/question/question.component';
import { MultipleChoiceComponent } from './form/hygiene-inspection/questionnaire/area/question/multiple-choice/multiple-choice.component';
import { ProductWithApplicationTimeComponent } from './form/hygiene-inspection/questionnaire/area/question/product-with-application-time/product-with-application-time.component';
import { ProductListComponent } from './form/hygiene-inspection/questionnaire/area/question/product-list/product-list.component';
import { ProductFieldComponent } from '../../shared/questionnaire/product-field/product-field.component';
import { ContactTimeFieldComponent } from '../../shared/questionnaire/contact-time-field/contact-time-field.component';
import { QuantityComponent } from './form/documents/document-table/quantity-input/quantity.component';
import { CleaningDeviceComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-device/cleaning-device.component';
import { SectionComponent } from './form/hygiene-inspection/questionnaire/area/section/section.component';
import { CleaningProgramComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-program/cleaning-program.component';
import { ProductWithApplicationTimeAndCleaningSolutionComponent } from './form/hygiene-inspection/questionnaire/area/question/product-with-application-time-and-cleaning-solution/product-with-application-time-and-cleaning-solution.component';
import { CleaningSolutionRowComponent } from '../../shared/questionnaire/cleaning-solution-row/cleaning-solution-row.component';
import { CleaningControlComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-control/cleaning-control.component';
import { CleaningDeviceSolutionComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-device-solution/cleaning-device-solution.component';
import { AddressComponent } from './form/hygiene-inspection/questionnaire/area/question/address/address.component';
import { AreaChangeListenerDirective } from './form/hygiene-inspection/questionnaire/area/area-change-listener.directive';
import { ManufacturerComponent } from './form/hygiene-inspection/questionnaire/area/question/manufacturer/manufacturer.component';
import { CleaningNameComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-name/cleaning-name.component';
import { NotesComponent } from './form/hygiene-inspection/questionnaire/area/section/notes/notes-dialog.component';
import { LocaleStringPipe } from '../../shared/util/pipes/locale-string.pipe';
import { ProductListWithMultipleDosesComponent } from './form/hygiene-inspection/questionnaire/area/question/product-list-with-multiple-doses/product-list-with-multiple-doses.component';
import { CleaningControlSolutionComponent } from './form/hygiene-inspection/questionnaire/area/question/cleaning-control-solution/cleaning-control-solution.component';
import { TextFieldComponent } from './form/hygiene-inspection/questionnaire/area/question/text-field/text-field.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    OrderDetailComponent,
    GeneralFormComponent,
    LogoUploadComponent,
    MedicalPractiseComponent,
    StatusChangeComponent,
    HygieneInspectionComponent,
    HistoryComponent,
    PreliminaryChecklistComponent,
    ProtocolComponent,
    InventoryComponent,
    AreaComponent,
    QuestionComponent,
    MultipleChoiceComponent,
    CleaningProgramComponent,
    ProductWithApplicationTimeComponent,
    ProductWithApplicationTimeAndCleaningSolutionComponent,
    ProductListComponent,
    CleaningDeviceComponent,
    CleaningControlComponent,
    ProductFieldComponent,
    ContactTimeFieldComponent,
    CleaningSolutionRowComponent,
    DocumentsComponent,
    UploadDocumentsComponent,
    AddressComponent,
    SectionComponent,
    DocumentsComponent,
    DocumentTableComponent,
    QuantityComponent,
    AddressComponent,
    DocumentTableComponent,
    AreaChangeListenerDirective,
    CleaningDeviceSolutionComponent,
    ManufacturerComponent,
    CleaningControlSolutionComponent,
    CleaningNameComponent,
    NotesComponent,
    ProductListWithMultipleDosesComponent,
    TextFieldComponent,

  ],
  imports: [
    FormsModule,
    CommonModule,
    UIModule,
    UtilModule,
    TranslateModule,
    OrderRoutingModule,
    ReactiveFormsModule,
    LoginModule,
    StepperModule,
    FormsModule,
    SharedModule,
  ],
  providers: [LocaleStringPipe],
})
export class OrderDetailModule {}
