import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Order } from '../../../order.model';

@Component({
  selector: 'hpm-documents',
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();
}
