<div class="history">
  <h3>{{ "ORDER_DETAIL.HISTORY.HEADLINE" | translate }}</h3>
  <div class="ruler"></div>

  <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="selectionChanged($event)">
    <mat-tab [label]="'ORDER_DETAIL.HISTORY.STATUS_CHANGES'|translate"></mat-tab>
    <mat-tab [label]="'ORDER_DETAIL.HISTORY.EXTENDED'|translate"></mat-tab>
  </mat-tab-group>

  <div class="history-entries">
    <div *ngFor="let history of timelineList; index as i" class="history-entry">
      <div class="history-status">
        @if (history.isLastState) {
          <div class="keepStatus current" [ngClass]="history.orderState"></div>
        } @else {
          <div class="keepStatus" [ngClass]="history.orderState"></div>
        }
        @if ((history.field === changeType.STATUS) || i === timelineList.length - 1) {
          <div class="history-status-label font-m" [ngClass]="history.orderState">
            {{ "ORDER_OVERVIEW.ORDER_STATUS." + history.orderState | translate }}
          </div>
        }
      </div>

      <div class="history-change font-m">
        <div class="history-date">{{ history.time | date: "dd.MM.yyyy HH:mm" }}</div>
        <div>{{ getChangeText(history) | async }}</div>
        <div *ngIf="history.comment"
             class="history-comment"
             [ngClass]="history.newValue === 'REMOVED'? 'removed' : ''">
          {{ history.comment }}
        </div>
      </div>
    </div>
  </div>
  <div class="spacer"></div>

  <div class="ruler"></div>
  <div class="history-footer">
    <button (click)="closeDialog()" color="accent" mat-flat-button>
      {{ "ORDER_DETAIL.HISTORY.BUTTON_CLOSE" | translate }}
    </button>
  </div>
</div>
