<div class="hygiene-inspection-wrapper">
  <div class="step-content-container">
    <div class="stepper-wrapper">
      <div class="step" (click)="setCurrentStep(1)" (keydown.enter)="setCurrentStep(1)" [class.active]="currentStep === 1" tabindex="0">
        <button class="step-icon"> 1</button>
        <span class="step-label">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.STEPPER.PRELIMINARY_CHECKLIST' | translate }}</span>
        <span class="vertical-line"></span>
      </div>
      <div class="step" (click)="setCurrentStep(2)" (keydown.enter)="setCurrentStep(1)" [class.active]="currentStep === 2" tabindex="0">
        <button class="step-icon"> 2</button>
        <span class="step-label">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.STEPPER.INVENTORY' | translate }}</span>
        <span class="vertical-line"></span>
      </div>
      <div class="step" (click)="setCurrentStep(3)" (keydown.enter)="setCurrentStep(1)" [class.active]="currentStep === 3" tabindex="0">
        <button class="step-icon"> 3</button>
        <span class="step-label">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.STEPPER.PROTOCOL' | translate }}</span>
      </div>
    </div>
    <div class="step-text-container">
      <div *ngIf="currentStep === 1" >
        <hpm-preliminary-checklist [order]="order"></hpm-preliminary-checklist>
      </div>
      <div *ngIf="currentStep === 2">
        <hpm-inventory [order]="order" (orderChange)="orderChange.emit()"></hpm-inventory>
      </div>
      <div *ngIf="currentStep === 3">
        <hpm-protocol [order]="order" (orderChange)="orderChange.emit()"></hpm-protocol>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button mat-stroked-button (click)="prevStep()" [disabled]="currentStep === 1" color="accent">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.STEPPER.PREVIOUS' | translate }}
    </button>
    <button mat-flat-button (click)="nextStep()" [disabled]="currentStep === 3" color="accent">{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.STEPPER.NEXT' | translate }}</button>
  </div>


</div>
