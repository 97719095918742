import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Notes, getEmptyNotes, Section } from '../../../model/section.model';
import { AreaChangeDetection } from '../../area-change-detection.service';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'hpm-section-notes',
  templateUrl: './notes-dialog.component.html',
  styleUrl: './notes-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit {
  @Input() section!: Section;
  opened = false;
  notes: Notes = getEmptyNotes();
  @ViewChild('notesButton') notesButton!: MatIconButton;
  @ViewChild('commentTextarea') commentTextarea!: ElementRef;

  constructor(
    private areaChangeDetection: AreaChangeDetection,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.abortDialog();
  }

  ngOnInit(): void {
    this.setSectionNotes();
  }

  private setSectionNotes(): void {
    if (this.section.notes) {
      this.notes = { ...this.section.notes };
      this.cdr.detectChanges();
    }
  }

  abortDialog(): void {
    this.closeDialog();
    this.setSectionNotes();
  }

  saveNotes(): void {
    this.closeDialog();
    this.section.notes = this.notes;
    this.areaChangeDetection.onChange(this.section.id);
    this.cdr.detectChanges();
  }

  openDialog(): void {
    this.opened = true;
    setTimeout(() => {
      this.commentTextarea?.nativeElement?.focus();
    }, 200);
  }

  private closeDialog(): void {
    this.opened = false;
    this.notesButton.focus();
  }

  isNotesPresent(): boolean {
    return !!(this.section.notes?.comment || this.section.notes?.qm);
  }
}
