import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ProductProposalService } from '../../../../../shared/service/product-proposal.service';
import { Order } from '../../../../order.model';
import { InventoryHttpService } from '../../../../../shared/service/inventory-http.service';
import { Inventory } from '../../../../../shared/model/inventory.model';

@Component({
  selector: 'hpm-inventory',
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss',
  // no encapsulation to apply uploader-style to preserve description line-breaks
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryComponent implements OnInit, OnDestroy {
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<void> = new EventEmitter<void>();

  private onDestroy$: Subject<void> = new Subject<void>();

  inventories: Inventory[] = [];

  constructor(
    private inventoryHttpService: InventoryHttpService,
    private cdr: ChangeDetectorRef,
    private productProposalService: ProductProposalService,
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.productProposalService.initialize();
    this.loadInventories();
  }

  private loadInventories(): void {
    this.inventoryHttpService
      .getInventoriesByOrder(this.order.id!)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((inventories) => {
        this.inventories = inventories.sort(this.inventorySortFn);
        this.cdr.detectChanges();
      });
  }

  private inventorySortFn(a: Inventory, b: Inventory): number {
    return a.rank - b.rank;
  }

  public getSelectedInventory(): Inventory[] {
    return this.inventories.filter((inventory) => inventory.checked);
  }

  toggleInventoryCheckmark(event: MatOptionSelectionChange<Inventory>): void {
    event.source.value.checked = event.source.selected;
  }

  saveSelection(): void {
    this.order.inventories = this.inventories
      .filter((i) => i.checked)
      .map((i) => i.name);
    this.orderChange.emit();
  }
}
