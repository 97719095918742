<cx-header>
  <cx-header-title>
    <div class="header-wrapper">
      <mat-icon
        (click)="saveOrder()"
        class="white-medium-icon"
        routerLink="/order"
      >list_alt
      </mat-icon>
      <div *ngIf="headerTitle; else newOrder">{{ headerTitle }}</div>
      <ng-template #newOrder>
        <div>{{ "ORDER_DETAIL.HEADER.NEW" | translate }}</div>
      </ng-template>
    </div>
  </cx-header-title>
  <cx-header-quickbar>
    <cx-menu>
      <cx-menu-item (click)="openHistory()"> {{ 'ORDER_DETAIL.MENU.HISTORY' | translate }}</cx-menu-item>
    </cx-menu>
  </cx-header-quickbar>
</cx-header>
<div class="detail-page-content container">
  @if(order) {
  <mat-tab-group [(selectedIndex)]="selectedTab">
    <mat-tab [label]="'ORDER_DETAIL.TAB.GENERAL' | translate"></mat-tab>
    <mat-tab [label]="'ORDER_DETAIL.TAB.ON_SITE_CHECK' | translate"></mat-tab>
    <mat-tab [label]="'ORDER_DETAIL.TAB.HYGIENE_PLAN' | translate"></mat-tab>
    <mat-tab [label]="'ORDER_DETAIL.TAB.DOCUMENTS' | translate"></mat-tab>
  </mat-tab-group>
  <hpm-general-form *ngIf="selectedTab === 0" [order]="order" (orderChange)="saveOrder($event)"></hpm-general-form>
  <hpm-hygiene-inspection *ngIf="selectedTab === 1" [order]="order" (orderChange)="saveOrder()"></hpm-hygiene-inspection>
  <hpm-documents *ngIf="selectedTab === 3"  [order]="order" (orderChange)="saveOrder()"></hpm-documents>
  } @else if (!isLoading){
    <div class="container">
      <cx-banner class="error-message" [message]="'ORDER_DETAIL.ERROR_MESSAGE' | translate" type="error"></cx-banner>
    </div>
  }
</div>
