@for (product of detail.products; track product) {
  <div class="product-input">

    <hpm-product-field [area]="area" [questionId]="questionId" [product]="product">
    </hpm-product-field>

    <button class="button-remove" (click)="removeLine($index)" mat-icon-button>
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="addLine($index)" mat-icon-button>
      <mat-icon>add</mat-icon>
    </button>
  </div>
}
