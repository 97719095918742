<h5>{{ "ORDER_DETAIL.DOCUMENTS.HEADLINE_UPLOADER" | translate }}</h5>

<div class="row gap">
  <div class="col-5 uploader">
    <cx-file-uploader
      [multiple]="true"
      [buttonText]=" 'ORDER_DETAIL.DOCUMENTS.UPLOAD.BUTTON' | translate"
      [description]=" 'ORDER_DETAIL.DOCUMENTS.UPLOAD.DESCRIPTION' | translate"
      [errorMessage]=" 'ORDER_DETAIL.DOCUMENTS.UPLOAD.ERROR_MESSAGE' | translate"
      [heading]=" 'ORDER_DETAIL.DOCUMENTS.UPLOAD.HEADING' | translate"
      [endpoint]="getUploaderEndpoint()"
      [acceptedFileTypes]="uploaderAcceptedFiletypes"
    ></cx-file-uploader>
  </div>

  <div class="col-7">
    <ng-container *ngFor="let document of documentList">
      <li class="cx-list-item d-flex border-radius inline-block">
        <mat-icon>receipt</mat-icon>
        <div class="document">
          <div>
            <a hpmSecureDownload [url]="downloadDocument(document.id)"
               [fileName]="document.filename">{{ document.filename }}</a>
          </div>
          <div class="fileSize">{{ bytesToHuman(document.size) }}</div>
        </div>
        <div class="spacer"></div>
        <button mat-icon-button (click)="deleteDocument(document.id)">
          <mat-icon>close</mat-icon>
        </button>
      </li>
      <div class="ruler"></div>
    </ng-container>
  </div>
</div>
