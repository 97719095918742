import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Product } from '../../../order/order-detail/form/hygiene-inspection/questionnaire/model/detail-questions.model';
import { ProductProposal } from '../../model/product-proposal';
import { ProductProposalService } from '../../service/product-proposal.service';

@Component({
  selector: 'hpm-product-field',
  templateUrl: './product-field.component.html',
  styleUrl: './product-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFieldComponent implements OnInit {
  @Input() product: Product = { product: '' };
  @Input() area!: string;
  @Input() questionId!: string;
  @Output() selectedProductProposal: EventEmitter<ProductProposal> =
    new EventEmitter<ProductProposal>();
  productProposals: ProductProposal[] = [];
  filteredProductProposals: ProductProposal[] = [];

  constructor(
    private productProposalService: ProductProposalService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.productProposals =
      this.productProposalService.getProductProposalsByAreaAndQustionId(
        this.area,
        this.questionId,
      ) || [];
    this.filteredProductProposals = this.productProposals;
    this.cdr.detectChanges();
  }

  public filterAutocomplete(): void {
    this.filteredProductProposals = [...this.productProposals].filter((p) =>
      p.name.toLowerCase().match(this.product.product.toLowerCase()),
    );
  }

  onAutocompleteSelected(selectedProductProposalId: string): void {
    const selectedProductProposal = this.productProposals.find(
      (p) => p.id === selectedProductProposalId,
    );
    if (selectedProductProposal?.name) {
      this.product.product = selectedProductProposal.name;
    }
    this.selectedProductProposal.emit(selectedProductProposal);
  }

  isAmbiguous(name: string): boolean {
    const nameMatching = this.productProposals.filter((product) => {
      return product.name === name;
    });
    return nameMatching.length > 1;
  }
}
