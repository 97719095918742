import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailAddress } from '../../../model/detail-questions.model';

@Component({
  selector: 'hpm-address',
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  @Input() detail: DetailAddress = {
    kind: '',
    name: '',
    street: '',
    zipAndCity: '',
  };
}
