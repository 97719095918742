import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AreaChangeDetection } from '../../area-change-detection.service';
import {
  DetailProductListWithMultipleDoses,
  ProductWithMultipleDoses,
} from '../../../model/detail-questions.model';
import { ProductProposal } from '../../../../../../../../shared/model/product-proposal';
import { ProductProposalService } from '../../../../../../../../shared/service/product-proposal.service';

@Component({
  selector: 'hpm-product-list-with-multiple-doses',
  templateUrl: './product-list-with-multiple-doses.component.html',
  styleUrl: './product-list-with-multiple-doses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ProductListWithMultipleDosesComponent implements OnInit {
  @Input() detail!: DetailProductListWithMultipleDoses;
  @Input() area!: string;
  @Input() questionId!: string;
  productProposals: (ProductProposal | undefined)[] = [];

  constructor(
    private productProposalService: ProductProposalService,
    private areaChangeDetection: AreaChangeDetection,
  ) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty(true);
  }

  private ensureInputNotEmpty(initialization: boolean): void {
    if (this.detail.products.length === 0) {
      const defaultProduct = this.productProposalService.getDefaultProduct(
        this.area,
        this.questionId,
      );
      if (initialization && defaultProduct) {
        this.detail.products.push(this.getDefaultItem(defaultProduct));
        this.productProposals.push(defaultProduct);
      } else {
        this.detail.products.push(this.getEmptyItem());
        this.productProposals = new Array(this.detail.products.length);
      }
    }
  }

  private getEmptyItem(): ProductWithMultipleDoses {
    return {
      product: '',
      applicationTime: ['', ''],
    };
  }

  private getDefaultItem(proposal: ProductProposal): ProductWithMultipleDoses {
    let doseLow = proposal.doses?.find(
      (d) => d.isDefaultDose && d.type === 'talgdrüsenarm',
    )?.dose;

    if (!doseLow) {
      doseLow = '';
    }

    let doseHigh = proposal.doses?.find(
      (d) => d.isDefaultDose && d.type === 'talgdrüsenreich',
    )?.dose;

    if (!doseHigh) {
      doseHigh = '';
    }

    return {
      product: proposal.name,
      applicationTime: [doseLow, doseHigh],
    };
  }

  addLine(index: number): void {
    this.detail.products.splice(index + 1, 0, this.getEmptyItem());
    this.productProposals.splice(index + 1, 0, undefined);
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    this.detail.products.splice(index, 1);
    this.productProposals.splice(index, 1);
    this.ensureInputNotEmpty(false);
    this.areaChangeDetection.onChange();
  }

  onProductProposalSelected(index: number, proposal: ProductProposal): void {
    this.productProposals[index] = proposal;
  }
}
