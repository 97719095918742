import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DetailCleaningDeviceSolution } from '../../../model/detail-questions.model';
import {
  VisibilityControl,
  VisibilityController,
} from '../../../service/visibility.controller';
import { ProductProposal } from '../../../../../../../../shared/model/product-proposal';
import { AreaChangeDetection } from '../../area-change-detection.service';
import { ProductProposalService } from '../../../../../../../../shared/service/product-proposal.service';

export enum DeviceSolutionTime {
  KURZZEITWERT = 'KURZZEITWERT',
  LANGZEITWERT = 'LANGZEITWERT',
}

@Component({
  selector: 'hpm-cleaning-device-solution',
  templateUrl: './cleaning-device-solution.component.html',
  styleUrl: './cleaning-device-solution.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class CleaningDeviceSolutionComponent implements OnInit {
  @Input() detail!: DetailCleaningDeviceSolution;
  @Input() visibilityController: VisibilityController =
    new VisibilityController();
  @Input() area!: string;
  @Input() questionId!: string;
  cleaningDeviceSolutionRowVisibilityControl: VisibilityControl =
    new VisibilityControl();
  protected readonly deviceSolutionEfficiency = DeviceSolutionTime;
  selectedProductProposal: Map<number, ProductProposal | undefined> = new Map<
    number,
    ProductProposal | undefined
  >();

  constructor(
    private areaChangeDetection: AreaChangeDetection,
    private productProposalService: ProductProposalService,
  ) {}

  ngOnInit(): void {
    this.ensureInputNotEmpty();
    this.cleaningDeviceSolutionRowVisibilityControl =
      this.visibilityController.buildVisibilityControlForCondition(
        this.detail.conditionForCleaningSolution,
      );
  }

  showCleaningDeviceSolutionRow(): boolean {
    return this.cleaningDeviceSolutionRowVisibilityControl.isShown();
  }

  private ensureInputNotEmpty(): void {
    if (this.detail.products?.length === 0) {
      this.detail.products = [
        {
          product: '',
          applicationTime: '',
          volumeUnit: 'L',
          concentrateUnit: '%',
        },
      ];
    }
  }

  addLine(index: number): void {
    this.detail.products.splice(index + 1, 0, {
      product: '',
      applicationTime: '',
      volumeUnit: 'L',
      concentrateUnit: '%',
    });
    this.areaChangeDetection.onChange();
  }

  removeLine(index: number): void {
    this.detail.products.splice(index, 1);
    this.ensureInputNotEmpty();
    this.areaChangeDetection.onChange();
  }

  onSelectProductProposal(
    index: number,
    productProposal: ProductProposal | undefined,
  ): void {
    if (productProposal) {
      this.selectedProductProposal.set(index, productProposal);
      if (!this.detail.products[index].time) {
        this.detail.products[index].time =
          productProposal.efficiency.toUpperCase();
      }
    }
  }

  changedProductTime(index: number): void {
    const allProductProposals =
      this.productProposalService.getProductProposalsByAreaAndQustionId(
        this.area,
        this.questionId,
      );
    const productProposals = allProductProposals?.filter((productProposal) => {
      return (
        productProposal.name === this.detail.products[index].product &&
        productProposal.efficiency.toUpperCase() ===
          this.detail.products[index].time
      );
    });
    if (productProposals?.length) {
      this.selectedProductProposal.set(index, productProposals[0]);
    } else {
      this.selectedProductProposal.set(index, undefined);
    }
  }
}
