<form [formGroup]="orderForm">
  <div class="general-form">
    <div class="form-column">

      @if (showCustomerTransactionNumberField) {
        <mat-form-field class="customer-transaction-number">
          <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.CUSTOMER_TRANSACTION_NUMBER' | translate }}</mat-label>
          <input formControlName="customerTransactionNumber" required matInput (focusout)="lookupCRMData()">
          <mat-error>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.CUSTOMER_TRANSACTION_NUMBER_NOT_FOUND' | translate }}</mat-error>
        </mat-form-field>
      }

      <!-- Logo-->
      <hpm-logo-upload [currentOrderId]="order.id!"></hpm-logo-upload>
    </div>

    <div class="form-column">
      <!--      Customer-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.CUSTOMER' | translate }}</mat-label>
        <input formControlName="customer" matInput>
        <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
      </mat-form-field>

      <!--      Contact-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.CONTACT' | translate }}</mat-label>
        <input formControlName="contact" matInput>
        <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
      </mat-form-field>

      <!--      telephone-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.TELEPHONE' | translate }}</mat-label>
        <input formControlName="telephone" matInput>
      </mat-form-field>

      <!--      email-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.EMAIL' | translate }}</mat-label>
        <input formControlName="email" matInput>
      </mat-form-field>

      <!--      hygiene representative-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.HYGIENE_REPRESENTATIVE' | translate }}</mat-label>
        <input formControlName="hygieneRepresentative" matInput>
      </mat-form-field>

      <!--      Address-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.ADDRESS' | translate }}</mat-label>
        <input formControlName="address" matInput>
        <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
      </mat-form-field>

      <!--      Medical Practice-->
      <hpm-medical-practise formControlName="medicalPractice">
      </hpm-medical-practise>

    </div>

    <div class="form-column">
      <!--      Reason-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.REASON' | translate }}</mat-label>
        <mat-select formControlName="reason">
          <mat-option *ngFor="let reason of reasons" [value]="reason">{{ reason }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!--      Commissioned by-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.COMMISSIONED_BY' | translate }}</mat-label>
        <input formControlName="commissionedBy" matInput>
      </mat-form-field>

      <!--      priority-->
      <div class="priority-container">
        <div class="priority">
          <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.PRIORITY' | translate }}</mat-label>
          <mat-slide-toggle formControlName="priority">
            <div
              class="toggle-text">{{ 'ORDER_DETAIL.GENERAL.FORM.PRIORITY.' + (this.orderForm.get('priority')?.value ? 'HIGH' : 'LOW') | translate }}
            </div>
          </mat-slide-toggle>
        </div>

        <!--      priority reason-->
        <div class="priority-grund">
          <mat-form-field *ngIf="this.orderForm.get('priority')?.value">
            <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.PRIORITY_REASON' | translate }}</mat-label>
            <input formControlName="priorityReason" matInput>
            <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!--      Status-->
      <hpm-status-change [userlist]="users"
                         formControlName="status"
                         [order]="order"
                         (orderChange)="orderChange.emit($event)"
                         [assignedTo]="orderForm.controls['assignedTo'].value"
                         (statusChange)="onStatusChange($any($event))">
      </hpm-status-change>

      <!--      assignedTo-->
      <mat-form-field>
        <mat-label>{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.ASSIGNED' | translate }}</mat-label>
        <mat-select [compareWith]="compareUser" formControlName="assignedTo">
          <mat-option *ngFor="let user of users" [value]="user"
          >{{ user.firstname + ' ' + user.lastname }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--      Specialty Field-->
      <hpm-multi-search-select
        #multiSearchSelectComponent
        [itemList]="specialties"
        divider=","
        formControlName="specialtyField"
        [label]="'ORDER_DETAIL.GENERAL.FORM.LABEL.SPECIALTY_FIELD' | translate"
        [multiFieldRequired]="multiFieldRequired"
      >
      </hpm-multi-search-select>

      <!--      required hygieneplan-->
      <hpm-multi-search-select
        [itemList]="plans"
        formControlName="requiredHygienePlans"
        [label]="'ORDER_DETAIL.GENERAL.FORM.LABEL.REQUIRED_HYGIENEPLAN' | translate"
        [multiFieldRequired]="multiFieldRequired"
      >
      </hpm-multi-search-select>
    </div>

    <div>
      <p class="user-label">{{ 'ORDER_DETAIL.GENERAL.FORM.LABEL.CREATED_BY' | translate }} </p>
      <p class="user-label-extern">{{ order.createdBy?.firstname }} {{ order.createdBy?.lastname }} </p>
    </div>

  </div>
</form>
