<button (click)="openDialog()"
        (keyup.space)="openDialog()"
        mat-icon-button
        type="button"
        #notesButton
        #trigger=cdkOverlayOrigin
        cdkOverlayOrigin
        class="cx-badge"
>
  <mat-icon [ngClass]="isNotesPresent() ? 'badge' : ''" class="material-symbols-outlined">chat_bubble</mat-icon>
  @if(isNotesPresent()){
    <span class="cx-badge-notification-sm-icon"></span>
  }
</button>


<ng-template
  (backdropClick)="abortDialog()"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayOrigin]="trigger"
  cdk-connected-overlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop">
  <div class="notes-overlay">
    <h5>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.NOTES.HEADLINE' | translate }}</h5>
    <div class="ruler"></div>
    <mat-form-field>
      <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.NOTES.COMMENT' | translate }}</mat-label>
      <textarea [(ngModel)]="notes.comment" #commentTextarea cdkTextareaAutosize matInput></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.NOTES.QM_REFERENCE' | translate }}</mat-label>
      <textarea [(ngModel)]="notes.qm" cdkTextareaAutosize matInput></textarea>
    </mat-form-field>
    <div class="button-line">
    <button color="accent" mat-flat-button (click)="saveNotes()">
      {{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.NOTES.SAVE' | translate }}
    </button>
    </div>
  </div>
</ng-template>
