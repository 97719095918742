import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { UserHttpService } from '../../../../../shared/service/user-http.service';
import { OrderStateEnum } from '../../../../../shared/model/order-state.enum';
import { User, UserRole } from '../../../../../shared/model/user.model';
import { Order } from '../../../../order.model';

@Component({
  selector: 'hpm-status-change',
  templateUrl: './status-change.component.html',
  styleUrl: './status-change.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusChangeComponent),
      multi: true,
    },
  ],
})
export class StatusChangeComponent
  implements ControlValueAccessor, AfterViewInit
{
  userAllowedStateTransitions: Map<UserRole, OrderStateEnum[]> = new Map([
    [
      UserRole.ADMIN,
      [
        OrderStateEnum.IN_CAPTURE,
        OrderStateEnum.IN_INCOMING_INSPECTION,
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
        OrderStateEnum.REQUEST_IN_PROCESSING,
        OrderStateEnum.REQUEST_IN_RELEASE,
        OrderStateEnum.IN_PROCESSING,
        OrderStateEnum.IN_RELEASE,
        OrderStateEnum.RELEASED,
        OrderStateEnum.COMPLETED,
      ],
    ],
    [
      UserRole.FIELD_SERVICE,
      [OrderStateEnum.IN_CAPTURE, OrderStateEnum.IN_INCOMING_INSPECTION],
    ],
    [
      UserRole.RETAIL_PARTNER,
      [OrderStateEnum.IN_CAPTURE, OrderStateEnum.IN_INCOMING_INSPECTION],
    ],
    [

      UserRole.OFFICE_SERVICE,
      [
        OrderStateEnum.IN_CAPTURE,
        OrderStateEnum.IN_INCOMING_INSPECTION,
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
        OrderStateEnum.REQUEST_IN_PROCESSING,
        OrderStateEnum.REQUEST_IN_RELEASE,
        OrderStateEnum.IN_PROCESSING,
        OrderStateEnum.IN_RELEASE,
        OrderStateEnum.RELEASED,
        OrderStateEnum.COMPLETED,
      ],
    ],
    [UserRole.MARKETING, []],
    [UserRole.CUSTOMER, []],
  ]);

  statusTransitionsMap = new Map<OrderStateEnum, OrderStateEnum[]>([
    [
      OrderStateEnum.IN_CAPTURE,
      [OrderStateEnum.IN_CAPTURE, OrderStateEnum.IN_INCOMING_INSPECTION],
    ],
    [
      OrderStateEnum.IN_INCOMING_INSPECTION,
      [
        OrderStateEnum.IN_INCOMING_INSPECTION,
        OrderStateEnum.IN_PROCESSING,
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
      ],
    ],
    [
      OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
      [
        OrderStateEnum.REQUEST_IN_INCOMING_INSPECTION,
        OrderStateEnum.IN_INCOMING_INSPECTION,
      ],
    ],
    [
      OrderStateEnum.IN_PROCESSING,
      [
        OrderStateEnum.IN_PROCESSING,
        OrderStateEnum.IN_RELEASE,
        OrderStateEnum.RELEASED,
        OrderStateEnum.REQUEST_IN_PROCESSING,
      ],
    ],
    [
      OrderStateEnum.REQUEST_IN_PROCESSING,
      [OrderStateEnum.IN_PROCESSING, OrderStateEnum.REQUEST_IN_PROCESSING],
    ],
    [
      OrderStateEnum.IN_RELEASE,
      [
        OrderStateEnum.RELEASED,
        OrderStateEnum.IN_RELEASE,
        OrderStateEnum.REQUEST_IN_RELEASE,
      ],
    ],
    [
      OrderStateEnum.REQUEST_IN_RELEASE,
      [OrderStateEnum.REQUEST_IN_RELEASE, OrderStateEnum.IN_RELEASE],
    ],
    [
      OrderStateEnum.RELEASED,
      [
        OrderStateEnum.RELEASED,
        OrderStateEnum.IN_PROCESSING,
        OrderStateEnum.COMPLETED,
      ],
    ],
    [
      OrderStateEnum.COMPLETED,
      [OrderStateEnum.COMPLETED, OrderStateEnum.IN_PROCESSING],
    ],
  ]);

  allowedStates: OrderStateEnum[] = [];

  selectedStatus!: OrderStateEnum;
  initialStatus: OrderStateEnum = this.selectedStatus;

  currentUser: User | undefined;

  @Input() userlist: User[] = [];
  @Input() order!: Order;
  @Output() orderChange: EventEmitter<string | undefined> = new EventEmitter<
    string | undefined
  >();
  @Input() assignedTo: User | undefined;
  @Output() statusChange = new EventEmitter<{
    oldStatus: OrderStateEnum;
    newStatus: OrderStateEnum;
    assignedTo: User;
  }>();

  commentControl: FormControl = new FormControl<string>('');

  @ViewChild('statusFormField') statusFormField: MatSelect | undefined;
  @ViewChild('templatePortalContent')
  templatePortalContent: TemplateRef<unknown> | undefined;
  templatePortal: TemplatePortal | undefined;
  overlayRef: OverlayRef | undefined = undefined;

  constructor(
    private cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private overlay: Overlay,
    private userService: UserHttpService,
  ) {}

  ngAfterViewInit(): void {
    this.loadCurrentUser();

    if (this.templatePortalContent) {
      this.templatePortal = new TemplatePortal(
        this.templatePortalContent,
        this.viewContainerRef,
      );
    }
  }

  compareUser(user1: User, user2: User): boolean {
    return user2?.username === user1?.username;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */ // any type required for CVA interface

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* eslint-enable */

  loadCurrentUser(): void {
    this.userService.getCurrentUser().subscribe((user) => {
      if (user) {
        this.currentUser = user;
        this.loadAllowedStatusTransitions();
      } else {
        this.currentUser = undefined;
      }
    });
  }

  loadAllowedStatusTransitions(): void {
    if (this.currentUser && this.currentUser.role) {
      const currentStatus = this.selectedStatus;

      // Get the allowed statuses based on the role
      const roleAllowedTransitions =
        this.userAllowedStateTransitions.get(this.currentUser.role) || [];

      // Get the allowed statuses based on the current status
      const statusBasedTransitions =
        this.statusTransitionsMap.get(currentStatus);
      //this.statusTransitions(currentStatus);

      // If the user is ADMIN or FIELD_SERVICE, allow all transitions
      if (
        this.currentUser.role === UserRole.ADMIN ||
        this.currentUser.role === UserRole.OFFICE_SERVICE
      ) {
        this.allowedStates = roleAllowedTransitions;
      } else {
        // Otherwise, take the intersection of both allowed transitions
        this.allowedStates = roleAllowedTransitions.filter((state) =>
          statusBasedTransitions!.includes(state),
        );
      }

      this.cdr.detectChanges();
    }
  }

  writeValue(value: OrderStateEnum): void {
    this.selectedStatus = value;
    this.initialStatus = this.selectedStatus;

    this.cdr.detectChanges();
  }

  closeWithSave(): void {
    const oldStatus = this.initialStatus;
    const newStatus = this.selectedStatus;

    this.initialStatus = this.selectedStatus;
    this.orderChange.emit(this.commentControl.value);
    this.commentControl.reset();

    this.statusChange.emit({
      oldStatus: oldStatus,
      newStatus: newStatus,
      assignedTo: this.assignedTo!,
    });
    this.closeDialog();
  }

  openDialog(event: MatSelectChange): void {
    if (this.overlayRef) {
      return; // do not open overlay if already open
    }
    this.selectedStatus = event.value;
    const overlayConfig = new OverlayConfig();
    overlayConfig.positionStrategy = this.overlay.position().global().end();
    overlayConfig.hasBackdrop = true;
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(this.templatePortal);
    this.overlayRef
      .keydownEvents()
      .pipe(filter((event: KeyboardEvent) => event.code === 'Escape'))
      .subscribe(() => this.abortDialog());
    this.overlayRef.backdropClick().subscribe(() => this.abortDialog());
  }

  abortDialog(): void {
    this.selectedStatus = this.initialStatus;
    this.commentControl.reset();
    this.closeDialog();
    this.cdr.detectChanges();
  }

  private closeDialog(): void {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
    if (this.statusFormField) {
      // fixme: on esc the medical-practice field gets in focus????
      this.statusFormField?.focus();
    }
  }
}
