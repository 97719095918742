import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductProposal } from '../model/product-proposal';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductHttpService {
  private readonly procuctBaseUrl: string = `${environment.baseUrl}/productProposals`;
  private productList: BehaviorSubject<ProductProposal[]> = new BehaviorSubject<
    ProductProposal[]
  >([]);

  constructor(private httpClient: HttpClient) {}

  getProductProposalList(): Observable<ProductProposal[]> {
    if (this.productList.getValue().length === 0) {
      this.loadProductList();
    }
    return this.productList;
  }

  private loadProductList(): void {
    this.httpClient
      .get<ProductProposal[]>(`${this.procuctBaseUrl}`)
      .subscribe((productList) => {
        this.productList.next(productList);
      });
  }

  onProductInvalidation(): void {
    this.productList.next([]);
    this.loadProductList();
  }
}
