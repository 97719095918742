<div class="cleaning-device">
  <mat-form-field class="type">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.TYPE' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.type"/>
  </mat-form-field>

  <mat-form-field class="company">
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.COMPANY' | translate }}</mat-label>
    <input matInput type="text" hpmAreaChangeListener [(ngModel)]="detail.company"/>
  </mat-form-field>
</div>


