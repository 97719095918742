<mat-form-field>
  @if (customLabel) {
    <mat-label>{{ customLabel | translate }}</mat-label>
  } @else {
    <mat-label>{{ 'ORDER_DETAIL.HYGIENE_INSPECTION.QUESTIONNAIRE.CONTACT_TIME' | translate }}</mat-label>
  }
  <input matInput type="text" #inputElement
         [(ngModel)]="applicationTime"
         (ngModelChange)="filterAutocompleteOptions()"
         [matAutocomplete]="autocomplete"
         class="contact-time"
         (blur)="applicationTimeChange.emit(applicationTime)"
         hpmAreaChangeListener />
</mat-form-field>

<mat-autocomplete #autocomplete>
  <mat-option *ngFor="let dose of filteredDoses" [value]="dose.dose">
    {{ dose.dose }}
  </mat-option>
</mat-autocomplete>
