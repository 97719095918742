import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { OrderHttpService } from '../../../order-http.service';
import { CxFileProgress } from '@bbraun/cortex/file-uploader/file-uploader.interface';

@Component({
  selector: 'hpm-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrl: './logo-upload.component.scss',
  // no encapsulation to apply uploader-style to preserve description line-breaks
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoUploadComponent implements OnInit, OnDestroy {
  @Input() currentOrderId!: string;
  uploaderVisible = false;
  uploaderAcceptedFiletypes = 'image/png, image/jpeg, image/gif, image/svg+xml';
  onDestroy$ = new Subject<void>();
  imageSrc = '';
  files: Array<CxFileProgress> = [];

  constructor(
    private httpService: OrderHttpService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadImage();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    if (this.imageSrc) {
      URL.revokeObjectURL(this.imageSrc);
    }
  }

  showUploaderDialog(): void {
    this.uploaderVisible = true;
  }

  private loadImage(): void {
    this.httpService
      .getOrderPraxislogoThumbnail(this.currentOrderId)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((image) => {
        if (image.size > 0) {
          this.imageSrc = URL.createObjectURL(image);
          this.uploaderVisible = false;
        } else {
          URL.revokeObjectURL(this.imageSrc);
          this.imageSrc = '/';
          this.files = [];
          this.uploaderVisible = true;
        }
        this.cdr.detectChanges();
      });
  }

  fileSelected(selectedFiles: File[]): void {
    this.files = selectedFiles.map((file) => {
      return { file } as CxFileProgress;
    });
    this.saveLogo();
  }

  fileRemoved(removedFile: File): void {
    const removeIndex = this.files.findIndex(
      (files) => files.file.name === removedFile.name,
    );
    this.files.splice(removeIndex, 1);
    this.deleteLogo(true);
  }

  deleteLogo(confirm: boolean): void {
    if (confirm) {
      this.httpService
        .deletePraxislogo(this.currentOrderId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loadImage();
        });
    }
  }

  private saveLogo(): void {
    this.httpService
      .savePraxislogo(this.currentOrderId, this.files[0].file)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.loadImage();
      });
  }
}
