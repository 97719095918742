@if (visibilityControl.isShown()) {
  <div class="question-card">
    <div class="question">
      <h6 class="font-m">{{ question.title | localeString }}</h6>
    </div>

    <div>
      <div>
        @if (question.withCheck) {
          <hpm-two-label-toggle
            (changeToggleButton)="onToggleChange($event)"
            [checked]="question.value"
          >
          </hpm-two-label-toggle>
        }
      </div>
      <div class="question-detail" [hidden]="!questionDetailsVisible()">
        @switch (question.detail.kind) {
          @case ("multiple-choice") {
            <hpm-multiple-choice [detail]="getDetailAsMultipleChoice()"/>
          }
          @case ("product-list") {
            <hpm-product-list [detail]="getDetailAsProductList()"
                              [area]="areaName"
                              [questionId]="question.id"/>
          }
          @case ("text-field") {
            <hpm-text-field
               [detail]="getDetailAsTextField()"
            />
          }
          @case ("product-list-with-application-time") {
            <hpm-product-with-application-time
              [detail]="getDetailAsProductListWithApplicationTime()"
              [area]="areaName"
              [questionId]="question.id"/>
          }
          @case ("cleaning-program") {
            <hpm-cleaning-program
              [detail]="getDetailAsCleaningProgram()"
            />
          }
          @case ("cleaning-device") {
            <hpm-cleaning-device
              [detail]="getDetailAsCleaningDevice()"/>
          }
          @case ("cleaning-name") {
            <hpm-cleaning-name
              [detail]="getDetailAsCleaningName()"/>
          }
          @case ("cleaning-device-solution") {
            <hpm-cleaning-device-solution
              [detail]="getDetailAsCleaningDeviceSolution()"
              [visibilityController]="visibilityController"
              [area]="areaName"
              [questionId]="question.id"/>
          }
          @case ("cleaning-control-solution") {
              <hpm-cleaning-control-solution
                [detail]="getDetailAsCleaningControlSolution()"
                [visibilityController]="visibilityController"
                [area]="areaName"
                [questionId]="question.id"/>
          }
          @case ("cleaning-control") {
            <hpm-cleaning-control
              [detail]="getDetailAsCleaningControl()"/>
          }
          @case ("product-list-with-application-time-and-cleaning-solution") {
            <hpm-product-with-application-time-and-cleaning-solution
              [detail]="getDetailAsProductListWithApplicationTimeAndCleaningSolution()"
              [visibilityController]="visibilityController"
              [area]="areaName"
              [questionId]="question.id"
            />
          }
          @case ("address") {
            <hpm-address [detail]="getDetailAsAddress()"></hpm-address>
          }
          @case ("contact") {
            <hpm-contact [detail]="getDetailAsManufacturer()"></hpm-contact>
          }
          @case ("product-list-with-multiple-doses") {
            <hpm-product-list-with-multiple-doses [questionId]="question.id"
                                                  [area]="areaName"
                                                  [detail]="getDetailAsProductListWithMultipleDoses()">

            </hpm-product-list-with-multiple-doses>
          }
          @case ("none") {
          }
          @default {
            <span>Unknown question type</span>
          }
        }
      </div>
    </div>
  </div>
}
